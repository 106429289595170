// Colors and variables
$primary-color: #3C3B54;
$secondary-color: #5A4D8D;
$accent-color: #690077;
$frost-color: rgba(255, 255, 255, 0.2);

// Background image and overlay
.signup-container {
  position: relative;
  display: flex;
  flex-direction: column; // Ensures vertical stacking
  justify-content: center;
  align-items: center;
  height: 220vh;
  background: url('/image/pikaso_edit_comic-art-graphic-novel-art-comic-illustration-hig (3).jpeg') no-repeat center center/cover;
  font-family: 'Arial', sans-serif;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
}

// Top section with logo
.top {
  position: absolute; // Positioning it at the top of the screen
  top: 0;
  left: 0; // Align it to the left edge
  z-index: 2; // Ensure it appears above the overlay
  padding: 20px; // Add padding to ensure it's not right at the edge

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start; // Align logo to the left
    width: 100%;

    .logo {
      height: 100px; // Adjust logo size as needed
    }
  }
}

// Form container with reduced width
.form-containerS {
  position: relative;
  z-index: 2;
  background-color: $frost-color;
  padding: 40px 50px;
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 100%;
  max-width: 400px;
  transition: box-shadow 0.4s ease;
  backdrop-filter: blur(15px);
  opacity: 0;
  transform: translateX(-20px);
  animation: fadeIn 1s ease-out forwards;

  &:hover {
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.4);
  }

  // Animation for form container
  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

// Title
.title1 {
  font-family: 'Times New Roman';
  font-size: 28px;
  margin-bottom: 30px;
  color: #ffffff;
  font-weight: 300;
}

// Input fields with reduced width and transition from the left
.input-field, .phone-input {
  width: 90%;
  padding: 12px 15px;
  margin: 10px 0;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease;

  &::placeholder {
    color: rgba(255, 255, 255, 0.7); // White placeholder text with slight opacity
  }

  &:focus {
    border-color: $accent-color;
    box-shadow: 0 0 10px rgba(244, 162, 97, 0.5);
    outline: none;
  }
}

// Password container and toggle button
.password-container {
  position: relative;
  width: 100%;
  
  .toggle-password {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    transition: color 0.3s ease;

    &:hover {
      color: $accent-color;
    }
  }
}

// Button with zoom-in effect
.signup-button {
  width: 90%;
  padding: 12px;
  border-radius: 8px;
  background-color: $accent-color;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border: none;
  margin-top: 20px;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: lighten($accent-color, 10%);
    transform: scale(1.05);
  }

  &:active {
    transform: scale(1);
  }
}

// Footer text
.footer-text {
  margin-top: 20px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
}

// Link
.link {
  color: $accent-color;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;

  &:hover {
    color: lighten($accent-color, 10%);
  }
}

// Image picker styles
.image-picker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0; // Adjust margin as needed
}

.image-preview {
  width: 100px; // Set width for the image container
  height: 100px; // Set height for the image container
  border-radius: 50%; // Makes the image circular
  overflow: hidden; // Ensures the image doesn't overflow the container
  border: 2px solid #ccc; // Border style (can be adjusted)
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; // Placeholder background color
}

.image-preview img {
  width: 100%; // Make the image fill the container
  height: auto; // Keep the aspect ratio
}

.image-placeholder {
  color: #999; // Placeholder text color
}

.chooseImageButton {
  margin-top: 10px; // Space between the image preview and button
  padding: 10px 20px; // Add padding for the button
  border: none; // Remove default border
  border-radius: 5px; // Rounded corners
  background-color: #007bff; // Primary color (adjust as needed)
  color: white; // Text color
  font-size: 16px; // Font size
  cursor: pointer; // Pointer cursor on hover
  transition: background-color 0.3s; // Smooth transition for hover effect
}

.chooseImageButton:hover {
  background-color: #0056b3; // Darker shade for hover effect
}
.location-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px;
  background: #f8f9fa28;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: auto;

  .input-field {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #dddddd75;
    border-radius: 8px;
    transition: 0.3s ease-in-out;

    &:focus {
      border-color: #690077;
      outline: none;
      box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
    }
  }

  .add-location-button {
    background: linear-gradient(135deg, #007bff, #0056b3);
    color: rgba(255, 255, 255, 0.507);
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    
    &:hover {
      background: linear-gradient(135deg, #0056b3, #003d80);
      transform: scale(1.05);
    }
  }

  .location-dropdown {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 2px solid #ddd;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.514);
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      border-color: #007bff;
    }
  }

  .location-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 0.418);
    padding: 8px 12px;
    border-radius: 6px;
    width: 100%;
    border: 1px solid #ddd;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: #f1f1f1;
    }

    span {
      font-size: 16px;
    }

    .remove-location-button {
      background: #ff4d4d;
      color: white;
      border: none;
      padding: 5px 10px;
      font-size: 14px;
      border-radius: 6px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: #cc0000;
      }
    }
  }
}

