$primary-color: #3C3B54;
$secondary-color: #5A4D8D;
$accent-color: #690077;
$highlight-color: #e0aaff;
$light-color: #fff;
$frost-color: rgba(255, 255, 255, 0.2);

.header {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #eae8ff;
  color: $light-color;

  .logo-container {
    .logo {
      height: 50px;
    }
  }

  .nav-bar {
    display: flex;
    gap: 20px;
    text-align: center;
    flex-grow: 1;
    justify-content: center;

    .nav-link {
      font-family: 'Times New Roman';
      color: black;
      text-decoration: none;
      font-size: 16px;
      border-radius: 5px;
      transition: background-color 0.3s ease, color 0.3s ease;

      &:hover {
        color: $accent-color;
      }
      &.active {
        color: $accent-color; // Set active link color
      }
    }
  }
}

.history-container {
  font-family: Arial, sans-serif;
  color: #333;

  .order-history {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    
    h2 {
      text-align: center;
      font-size: 2rem;
      margin-bottom: 1.5rem;
      color: #333;
      font-weight: bold;
    }

    .history-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1rem;

      table {
        width: 100%;
        border-collapse: collapse;
        overflow: hidden;
        border-radius: 8px;

        thead {
          background-color: #4CAF50;
          color: #fff;
          
          th {
            padding: 1rem;
            text-align: left;
            font-size: 1.1rem;
          }
        }

        tbody {
          tr {
            transition: background-color 0.3s;

            &:nth-child(even) {
              background-color: #f2f2f2;
            }

            &:hover {
              background-color: #e8f5e9;
            }

            td {
              padding: 0.75rem;
              text-align: left;
              border-bottom: 1px solid #ddd;
              font-size: 1rem;
            }
          }
        }
      }
    }

    .error-message {
      color: #d9534f;
      text-align: center;
      font-size: 1.2rem;
      margin-top: 1rem;
    }
  }
}
.order-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 orders per row */
  gap: 20px;
  margin: 20px 0;
}

.order-card {
  background-color: #f9f9f9;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.order-card h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.order-card p {
  margin: 0.3rem 0;
}

.order-history {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  h2 {
    text-align: center;
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }

  p {
    font-size: 1rem;
    color: #666;
  }

  .no-orders {
    text-align: center;
    font-size: 1.2rem;
    color: #888;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 0;
    list-style-type: none;

    li {
      background-color: #fff;
      padding: 15px;
      border-radius: 8px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
      }

      p {
        margin: 5px 0;
        font-size: 1rem;
        color: #333;

        &:first-child {
          font-weight: bold;
        }
      }

      .order-id {
        color: #007bff;
        font-weight: bold;
      }

      .status {
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 0.9rem;
        margin-top: 10px;
        display: inline-block;

        &.pending {
          background-color: #ffcc00;
          color: #fff;
        }

        &.completed {
          background-color: #28a745;
          color: #fff;
        }

        &.cancelled {
          background-color: #dc3545;
          color: #fff;
        }
      }

      .order-details {
        font-style: italic;
        color: #555;
      }
    }
  }

  @media (max-width: 768px) {
    ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 480px) {
    ul {
      grid-template-columns: 1fr;
    }
  }
}
.profile-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img {
  width: 75px;        // Set the width of the image
  height: 75px;       // Set the height of the image (same as width for a perfect circle)
  border-radius: 50%;  // This makes the image circular
  object-fit: cover;   // This ensures the image maintains its aspect ratio while filling the circle
}
.profile-info{
  color: black;
}
.no-orders {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  border-radius: 10px;
  background: #f8f9fa;
  color: #333;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in-out;

  h3 {
    font-size: 24px;
    font-weight: bold;
    color: #690077;
  }

  p {
    font-size: 18px;
    color: #666;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.order-history {
  text-align: center;
  padding: 20px;
}

.filter-container {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
  padding: 10px 15px;
  background: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  label {
    font-size: 18px;
    font-weight: 600;
    color: #333;
  }

  input {
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 8px;
    border: 2px solid #ddd;
    outline: none;
    transition: all 0.3s ease;
    background: #fff;
    color: #333;
    
    &:hover {
      border-color: #007bff;
    }

    &:focus {
      border-color: #007bff;
      box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
    }
  }
}


.order-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.order-card {
  background: #fff;
  border-radius: 8px;
  padding: 15px;
  margin: 10px;
  width: 300px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  .order-details {
    text-align: left;
    font-size: 16px;
    line-height: 1.6;
  }

  .status {
    padding: 4px 8px;
    border-radius: 5px;
    font-weight: bold;
    text-transform: capitalize;

    &.pending {
      background: #ffcc00;
      color: #000;
    }

    &.completed {
      background: #4caf50;
      color: #fff;
    }

    &.canceled {
      background: #f44336;
      color: #fff;
    }
  }
}

.no-orders {
  text-align: center;
  font-size: 18px;
  color: #888;
}
