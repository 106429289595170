// Colors and variables
$primary-color: #3C3B54;
$secondary-color: #5A4D8D;
$accent-color: #690077;
$highlight-color: #e0aaff;
$light-color: #fff;
$frost-color: rgba(255, 255, 255, 0.2);

// Header styling
.header {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #eae8ff;
  color: $light-color;

  .logo-container {
    .logo {
      height: 50px;
    }
  }

  .nav-bar {
    display: flex;
    gap: 20px;
    text-align: center;
    flex-grow: 1;
    justify-content: center;

    .nav-link {
      font-family: 'Times New Roman';
      color: black;
      text-decoration: none;
      font-size: 16px;
      border-radius: 5px;
      transition: background-color 0.3s ease, color 0.3s ease;

      &:hover {
        color: $accent-color;
      }
    }
  }

  .profile-container {
    display: flex;
    align-items: center;

    .profile-image {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .profile-name {
      font-family: 'Times New Roman';
      color: black;
      font-size: 16px;
    }
  }
}

// Hero section
.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 60vh;
  background: url('/image/pikaso_texttoimage_amazing-background-image-with-retro-wave-vibe-with (1).jpeg') no-repeat center center/cover;
  color: $light-color;
  text-align: center;
  padding: 20px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }

  .hero-content {
    max-width: 600px;
    position: relative;
    z-index: 2;

    h1 {
      font-family: 'Times New Roman';
      color: $accent-color;
      font-size: 54px;
      margin-bottom: 20px;
    }

    p {
      font-family: 'Times New Roman';
      font-size: 1.2rem;
      margin-bottom: 20px;
      color: #eae8ff;
    }

    .cta-button {
      padding: 15px 30px;
      border-radius: 25px;
      background-color: $highlight-color;
      color: $light-color;
      text-decoration: none;
      font-size: 1rem;
      transition: background-color 0.3s ease, transform 0.3s ease;

      &:hover {
        background-color: lighten($highlight-color, 10%);
        transform: scale(1.05);
      }
    }
  }
}

// Order form section
.order-form {
  background-color: #0e07d6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background: $frost-color;
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
  width: 80%;
  margin: auto;
  max-width: 1200px;

  .form-content {
    flex: 1;
    max-width: 500px;

    h2 {
      font-size: 2rem;
      margin-bottom: 20px;
      color: #690077;
      text-align: center;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .form-group {
        display: flex;
        flex-direction: column;

        input, textarea {
          width: 100%;
          padding: 12px 15px;
          border-radius: 8px;
          border: 1px solid rgba(255, 255, 255, 0.5);
          background-color: rgba(255, 255, 255, 0.1);
          color: #000000;
          font-size: 15px;
          transition: all 0.4s ease;
          box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);

          &::placeholder {
            color: rgba(19, 18, 18, 0.7);
          }

          &:focus {
            border-color: $accent-color;
            box-shadow: 0 0 10px rgba(244, 162, 97, 0.5);
            outline: none;
          }
        }

        textarea {
          resize: vertical;
        }
      }

      button {
        width: 100%;
        padding: 12px;
        border-radius: 8px;
        background-color: $accent-color;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        border: none;
        transition: background-color 0.3s ease, transform 0.2s ease;

        &:hover {
          background-color: lighten($accent-color, 10%);
          transform: scale(1.05);
        }

        &:active {
          transform: scale(1);
        }
      }
    }
  }

  .lottie-container {
    flex: 1;
    max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// Footer styling
.footer {
  padding: 20px;
  text-align: center;
  background-color: #eae8ff;
  color: black;
}
// Tab bar styling
.tab-bar {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;

  .tab {
    padding: 10px 20px;
    font-size: 18px;
    background-color: $secondary-color;
    color: $light-color;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 10px;
    border-radius: 5px;

    &.active {
      background-color: $accent-color;
      font-weight: bold;
    }

    &:hover {
      background-color: lighten($secondary-color, 10%);
    }
  }
}

// Form container and slide transition
// Form container and slide transition
// Form container and slide transition
// Form container and slide transition
.form-container {
  height: auto; // Set to auto to ensure it adjusts based on content
  min-height: 115vh; // Ensure it takes at least 80% of the viewport height
  position: relative;
  width: 100%;
  max-width: 1300px;
  margin: auto;
  overflow: hidden; // Ensures the forms don't overflow
  display: flex; // Flex layout to keep form and Lottie side by side
  justify-content: space-between;
  align-items: center; // Vertically center content
}

.form-section {
  position: absolute;
  top: 0;
  left: 100%; // Start outside the view
  width: 100%;
  display: flex; // Ensure flex layout inside the form
  justify-content: space-between; // Space out form content and Lottie
  align-items: center;
  transition: left 0.5s ease;

  &.active-slide {
    left: 0; // Slide in when active
  }

  .form-content {
    flex: 1;
    max-width: 500px;
    padding: 40px;
    background-color: $frost-color;
    border-radius: 15px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(15px);

    h2 {
      font-size: 2rem;
      margin-bottom: 20px;
      color: #690077;
      text-align: center;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .form-group {
        display: flex;
        flex-direction: column;

        .dropdown {
          width: 105%;
          padding: 12px 15px;
          border-radius: 8px;
          border: 1px solid rgba(255, 255, 255, 0.5);
          background-color: rgba(255, 255, 255, 0.1);
          color: #000000;
          font-size: 15px;
          transition: all 0.4s ease;
          box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
          cursor: pointer;
          appearance: none; /* Remove default dropdown styling */
          -webkit-appearance: none; /* Remove default dropdown styling in Safari */
          -moz-appearance: none; /* Remove default dropdown styling in Firefox */
      
          &::placeholder {
            color: rgba(19, 18, 18, 0.7);
          }
      
          &:focus {
            border-color: $accent-color;
            box-shadow: 0 0 10px rgba(244, 162, 97, 0.5);
            outline: none;
          }
      
          option {
            background-color: rgba(255, 255, 255, 0.9);
            color: #000000;
            padding: 10px;
          }
      
          &:disabled {
            background-color: rgba(255, 255, 255, 0.2);
            color: rgba(0, 0, 0, 0.5);
          }
        }

        input, textarea {
          width: 100%;
          padding: 12px 15px;
          border-radius: 8px;
          border: 1px solid rgba(255, 255, 255, 0.5);
          background-color: rgba(255, 255, 255, 0.1);
          color: #000000;
          font-size: 15px;
          transition: all 0.4s ease;
          box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);

          &::placeholder {
            color: rgba(19, 18, 18, 0.7);
          }

          &:focus {
            border-color: $accent-color;
            box-shadow: 0 0 10px rgba(244, 162, 97, 0.5);
            outline: none;
          }
        }

        textarea {
          resize: vertical;
        }
      }

      button {
        width: 100%;
        padding: 12px;
        border-radius: 8px;
        background-color: $accent-color;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        border: none;
        transition: background-color 0.3s ease, transform 0.2s ease;

        &:hover {
          background-color: lighten($accent-color, 10%);
          transform: scale(1.05);
        }

        &:active {
          transform: scale(1);
        }
      }
    }
  }

  .lottie-container {
    flex: 1;
    max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; // Ensure the Lottie container takes the full height of the form
  }
}

// Reuse the existing order-form styles for consistency
.order-form {
  background-color: #0e07d6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background: $frost-color;
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
  width: 80%;
  margin: auto;
  max-width: 1200px;

  .form-content {
    flex: 1;
    max-width: 500px;

    h2 {
      font-size: 2rem;
      margin-bottom: 20px;
      color: #690077;
      text-align: center;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }

  .lottie-container {
    flex: 1;
    max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; // Ensure the Lottie container takes the full height of the form
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6); // Darkened background for more contrast
  z-index: 1000;

  .modal-content {
    background: linear-gradient(145deg, #ffffff, #f0f0f0); // Soft gradient background
    padding: 30px;
    border-radius: 15px; // Softer border radius
    text-align: center;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); // More elegant shadow

    h2 {
      margin-bottom: 20px;
      font-size: 1.8rem;
      color: #333;
      letter-spacing: 0.5px; // Slight letter spacing for elegance
    }

    .close-button {
      padding: 12px 25px;
      background-color: #690077; // Elegant blue color for the button
      color: white;
      font-size: 1rem;
      font-weight: bold;
      border: none;
      border-radius: 30px; // More rounded button
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.2s ease; // Smooth transition

      &:hover {
        background-color: #8d4397; // Darker blue on hover
        transform: translateY(-2px); // Subtle lift effect
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.3); // Elegant focus ring
      }
    }
  }
}
