@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.declined-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom right, #1e1e2f, #28293d);
  font-family: 'Poppins', sans-serif;

  .declined-container {
    background: rgba(255, 255, 255, 0.1);
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
    max-width: 500px;
    width: 90%;
    color: white;
    backdrop-filter: blur(10px);
    
    .declined-icon {
      font-size: 60px;
      color: #ffcccb;
      margin-bottom: 10px;
    }

    .declined-title {
      font-size: 26px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .declined-message {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 20px;

      .email-link {
        color: #ffcccb;
        font-weight: 500;
        text-decoration: none;
        transition: 0.3s;

        &:hover {
          color: white;
          text-decoration: underline;
        }
      }
    }

    .back-btn {
      padding: 12px 20px;
      border: none;
      background: white;
      color: #ff4b2b;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      border-radius: 6px;
      transition: 0.3s;

      &:hover {
        background: #ffcccb;
        color: black;
      }
    }
  }
}
