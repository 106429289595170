.pending-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f3f3f3;
  }
  
  .pending-content {
   
    display: flex;
    align-items: center;
    text-align: left;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 1500px;
    max-height: 1500px;
  }
  
  .pending-animation {
    width: 550px;
    height: 550px;
    margin-right: 20px;
  }
  
  .text-content h2 {
    font-size: 1.5rem;
    color: #6a1b9a; // Purple shade
    margin-bottom: 10px;
  }
  
  .text-content p {
    color: #555;
    margin-bottom: 20px;
  }
  
  .back-to-login-button {
    background-color: #6a1b9a; // Purple shade for the button
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s ease;
  
    &:hover {
      background-color: #4a148c;
    }
  }
  