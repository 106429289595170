
  

  
  /* Header Styling */
  .header {
   
      margin: 0;
      position: fixed; /* Keeps it at the top */
      top: 0;
      left: 50%; /* Move the header to the center */
      transform: translateX(-50%); /* Shift it back by half of its width */
      width: 100%; /* Adjust width as needed */
      z-index: 1000;
      background: #ffffff;
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      padding: 10px 20px; /* Add some padding */
 
  
  
    .logo-container .logo {
      padding-left: 50px;
      width: 60px;
      height: auto;
      transition: transform 0.3s ease;
  
      &:hover {
        transform: scale(1.1);
      }
    }
  
    .nav-bar {
      display: flex;
      gap: 2.5rem;
  
      .nav-link {
        font-size: 1.1rem;
        color: black;
        text-decoration: none;
        font-weight: 500;
        cursor: pointer;
        position: relative;
        transition: color 0.3s ease;
  
        &:hover {
          color: #0056b3;
        }
  
        &::after {
          content: '';
          width: 0;
          height: 2px;
          background: #007bff;
          position: absolute;
          bottom: -3px;
          left: 0;
          transition: width 0.3s ease;
        }
  
        &:hover::after {
          width: 100%;
        }
      }
    }
  
    .profile-image .profile-img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: cover;
      border: 3px solid #007bff;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
      transition: transform 0.3s ease;
  
      &:hover {
        transform: scale(1.1);
      }
    }
  
    .profile-info h3 {
      padding-right: 50px;
        font-family: 'Times New Roman', Times, serif;
      font-size: 1.2rem;
      color: #333333;
      margin: 0;
      font-weight: 600;
    }
  }
  
  /* Settings Page */
  .settings-page {
   
    max-width: 900px;
    margin-top: 150px;
    margin-left: 180px;
    padding: 2rem;
    background: #fff;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  
    .settings-container {
      margin-left: 200px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 5rem;
  
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
  
      .password-section,
      .image-section {
        flex: 1;
      }
  
      .password-section {
        width: 100%;
        max-width: 450px;
  
        h2 {
          font-size: 1.5rem;
          margin-bottom: 1rem;
        }
  
        label {
          font-weight: 600;
          display: block;
          margin-bottom: 0.5rem;
        }
  
        input {
          width: 100%;
          padding: 0.75rem;
          border: 1px solid #ccc;
          border-radius: 8px;
          margin-bottom: 1rem;
        }
  
        button {
          width: 100%;
          background: #007bff;
          color: white;
          padding: 0.75rem;
          border: none;
          border-radius: 8px;
          cursor: pointer;
          transition: background 0.3s;
  
          &:hover {
            background: #0056b3;
          }
        }
      }
  
      .image-section {
        text-align: center;
  
        img {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          object-fit: cover;
          margin-bottom: 1rem;
        }
  
        label {
          margin-top: 100px;
          display: inline-block;
          background: #690077;
          color: white;
          padding: 0.75rem ;
          border-radius: 8px;
          cursor: pointer;
          transition: background 0.3s;
  
          &:hover {
            background: #218838;
          }
  
          input {
            display: none;
          }
        }
      }
    }
  }
  .location-container1 {
    margin-top: 50px;
    margin-left: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
    background: #f8f9fa28;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
}

.input-field {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #dddddd75;
    border-radius: 8px;
    transition: 0.3s ease-in-out;
}

.input-field:focus {
    border-color: #690077;
    outline: none;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
}

.add-location-button {
    background: linear-gradient(135deg, #007bff, #0056b3);
    color: rgba(255, 255, 255, 0.9);
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    width: 100%; /* Make button full width */
    text-align: center;
}

.add-location-button:hover {
    background: linear-gradient(135deg, #0056b3, #003d80);
    transform: scale(1.05);
}

.location-dropdown {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 2px solid #ddd;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.514);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.location-dropdown:hover {
    border-color: #007bff;
}

/* Updated saved locations to be stacked below the button */
.saved-locations {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin-top: 10px;
}

.location-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 0.418);
    padding: 8px 12px;
    border-radius: 6px;
    width: 100%;
    border: 1px solid #ddd;
    transition: all 0.3s ease-in-out;
}

.location-item:hover {
    background: #f1f1f1;
}

.location-item span {
    font-size: 16px;
}

.remove-location-button {
    background: #ff4d4d;
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.remove-location-button:hover {
    background: #cc0000;
}

  .save-locations-button {
    padding: 10px 15px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .save-locations-button:hover {
    background-color: #45a049;
  }
  