.reset-password-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: url('/image/pikaso_edit_comic-art-graphic-novel-art-comic-illustration-hig (3).jpeg') no-repeat center center/cover;
  color: #fff; /* Ensure text is visible on the background */
  font-family: 'Arial', sans-serif;
}

.reset-password-card {
  background: rgba(255, 255, 255, 0.9); /* Slight transparency to blend with background */
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.title {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #333; /* Text inside the card should remain readable */
}

.subtitle {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #666;
}

.reset-password-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input {
  padding: 0.8rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border 0.3s;

  &:focus {
      outline: none;
      border: 1px solid #6a11cb;
  }
}

.btn {
  padding: 0.8rem;
  font-size: 1rem;
  color: #fff;
  background: #690077;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
      background: #4e0cb3;
  }
}

.message {
  margin-top: 1rem;
  font-size: 1rem;

  &.success {
      color: #28a745;
  }

  &.error {
      color: #dc3545;
  }
}
