.suspend-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(to bottom right, #1e1e2f, #28293d);
    padding: 20px;
  }
  
  .suspend-card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 40px;
    max-width: 400px;
    text-align: center;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .suspend-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .suspend-icon {
    color: #ff4b5c;
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
  
  .suspend-title {
    color: white;
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .suspend-message {
    color: #d1d1d1;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .suspend-button {
    background: #ff4b5c;
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 24px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: 0.3s ease;
    box-shadow: 0 4px 10px rgba(255, 75, 92, 0.4);
  
    &:hover {
      background: #e04350;
      transform: scale(1.05);
    }
  
    &:active {
      transform: scale(0.95);
    }
  }
  